.AttendanceParkingPage .parentViewTop {
    margin-top: 40px;
    margin-bottom: 20px;
    margin-left: 20px;
    width: calc( 100% - 40px );
    display: block;
    box-shadow: 0px 0px 3px rgba(0 ,0,0,0.3);
    border-radius: 10px;
}

.AttendanceParkingPage .parentViewTop .viewTop {
    margin-top: 20px;
    margin-bottom: 20px;
    display: inline-block;
    width: 100%;
}


.AttendanceParkingPage .parentViewTop .viewTop .addressParking {
    margin-left: 20px;
    text-align: left;
    width: calc( 100% - 40px );
    font-size: 15pt;
    font-weight: 500;
}

.AttendanceParkingPage .parentViewTop .viewTop .datapicker {
    width: calc( 40% - 40px );
    min-width: 350px;
    margin-left: 20px;
    margin-top: 20px;
}

.AttendanceParkingPage .tableData {
    width: calc( 100% - 40px );
    margin-left: 20px;
    margin-top: 20px;
}

.AttendanceParkingPage .report {
    width: calc( 100% - 40px );
    margin-left: 20px;
    font-size: 15pt;
    font-weight: 500;
}

.AttendanceParkingPage .parentViewTop .viewTop .buttons {
    margin-left: 20px;
    margin-top: 20px;
    display: table;
}

.AttendanceParkingPage .parentViewTop .viewTop .buttons .viewButtonFind {
    width: 130px;
    display: inline-block;

}

.AttendanceParkingPage .parentViewTop .viewTop .buttons .viewButtonExport {
    width: 200px;
    display: inline-block;
    margin-left: 20px;
}

.AttendanceParkingPage .parentViewTop .viewTop .buttons .viewButtonFind .buttonFind {
    margin-left: 0px;
    background-color: #FFCC00;
    color: black;
    box-shadow: 0px 0px 2px rgba(0 , 0, 5, 0.2);
    border: none;
    width: 100%;
    height: 38px;
    border-radius: 5px;
    font-size: 13pt;
}

.AttendanceParkingPage .parentViewTop .viewTop .buttons .viewButtonExport .buttonExport {
    margin-left: 0px;
    background-color: white;
    color: black;
    border: none;
    width: 100%;
    height: 38px;
    border-radius: 5px;
    font-size: 13pt;
    box-shadow: 0px 0px 2px rgba(0 , 0, 5, 0.2);
    white-space: nowrap
}

.AttendanceParkingPage .parentViewTop .viewTop .buttons .viewButtonFind .buttonFind:hover {
    box-shadow: 0px 0px 3px rgba(0 , 0, 5, 0.4);
}

.AttendanceParkingPage .parentViewTop .viewTop .buttons .viewButtonExport .buttonExport:hover {
    box-shadow: 0px 0px 3px rgba(0 , 0, 5, 0.4);
}

.AttendanceParkingPage .attendance_title {
    font-size: 15pt;
    font-weight: 500;
}

.noData {
    font-size: 14pt;
}

@media screen and (max-width: 430px) {
    .AttendanceParkingPage .parentViewTop .viewTop .datapicker {
        min-width: 0px;
        width: calc( 100% - 40px);
        margin: 20 auto;
    }

    .AttendanceParkingPage .parentViewTop .viewTop .buttons {
        width: calc( 100% - 40px );
    }

    .AttendanceParkingPage .parentViewTop .viewTop .buttons .viewButtonFind {
        width: calc( 50% - 10px );
    }

    .AttendanceParkingPage .parentViewTop .viewTop .buttons .viewButtonExport {
        width: calc( 50% - 10px );
    }

    .AttendanceParkingPage .parentViewTop .viewTop .addressParking {
        text-align: center;
    }

    .AttendanceParkingPage .parentViewTop .viewTop .addressParking {
        margin-left: 10px;
        width: calc( 100% - 20px );
    }

    .AttendanceParkingPage .parentViewTop .viewTop .addressParking {
        font-size: 14pt;
    }

    .AttendanceParkingPage .report {
        font-size: 14pt;
    }
}

@media screen and (max-width: 360px) {
    .AttendanceParkingPage .parentViewTop .viewTop .buttons .viewButtonExport .buttonExport {
        font-size: 12pt;
    }

    .AttendanceParkingPage .parentViewTop .viewTop .buttons .viewButtonFind .buttonFind {
        font-size: 12pt;
    }
}

@media screen and (max-width: 355px) {

    .AttendanceParkingPage .parentViewTop {
        width: calc( 100% - 20px );
        margin-left: 10px;
        margin-bottom: 10px;
    }

    .AttendanceParkingPage .parentViewTop .viewTop {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .AttendanceParkingPage .parentViewTop .viewTop .addressParking {
        margin-left: 10px;
        width: calc( 100% - 20px );
    }
    
    .AttendanceParkingPage .parentViewTop .viewTop .datapicker {
        width: calc( 100% - 20px );
        margin-left: 10px;
    }

    .AttendanceParkingPage .report {
        width: calc( 100% - 20px );
        margin-left: 10px;
    }
    
    .AttendanceParkingPage .parentViewTop .viewTop .buttons {
        margin-left: 10px;
        width: calc( 100% - 20px );
        margin-top: 10px;
        display: table;
    }

    .AttendanceParkingPage .parentViewTop .viewTop .buttons .viewButtonFind {
        width: calc( 40% - 5px );
    }

    .AttendanceParkingPage .parentViewTop .viewTop .buttons .viewButtonExport {
        margin-left: 10px;
        width: calc( 60% - 5px );
    }

    .AttendanceParkingPage .parentViewTop .viewTop .buttons .viewButtonExport .buttonExport {
        font-size: 10pt;
    }

    .AttendanceParkingPage .parentViewTop .viewTop .buttons .viewButtonFind .buttonFind {
        font-size: 10pt;
    }

    .AttendanceParkingPage .parentViewTop .viewTop .addressParking {
        font-size: 12pt;
    }

    .AttendanceParkingPage .report {
        font-size: 12pt;
    }

    .AttendanceParkingPage .parentViewTop .viewTop .datapicker input {
        font-size: 10pt;
    }

    .AttendanceParkingPage .tableData input {
        font-size: 10pt;
    }
}