.dateTimePicker {
    width: 100%;
    height: 30px;
}

.dateTimePicker input {
    margin-top: -9px;
    font-size: 11pt;
}

.dateTimePicker label {
    font-size: 9pt;
    margin-top: -2px;
}