.dropdown {
    width: 100%;
    height: 100%;
    margin-top: 0;
    margin-left: 0;
}

.dropdown .dropdownList-btn {
    margin-top: 0;
    padding: 0px 12px;
    background: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    height: 40px;
    background-color: transparent;
}

.dropdown .dropdownList-content {
    position: absolute;
    top: 110%;
    left: 0;
    padding: 10px;
    background: #fff ;
    box-shadow: 3px 3px 10px 6px rgba(0 ,0,0,0.06);
    border-radius: 10px;
    color: #333;
    width: 120%;
    text-align: left;
    z-index: 100;
}

.dropdown .dropdownList-content .dropdownList-item {
   padding: 10px; 
   cursor: pointer;
   transition: all 0.2s;
}

.dropdown .dropdownList-content .dropdownList-item:hover {
    background: #f4f4f4;
}

@media screen and (max-width: 1150px) {
    .dropdown .dropdownList-content {
        width: 90%;
    }
}

@media screen and (max-width: 400px) {
    .dropdown .dropdownList-btn {
        font-size: 11pt;
    }
}