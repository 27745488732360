.AccessListPage .viewParentTop {
    width: 90%;
    margin-left: 5%;
    margin-top: 40px;
    margin-bottom: 30px;
    height: 100px;
    display: block;
}

.AccessListPage .viewParentTop .viewTop {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
}

.AccessListPage .viewParentTop .viewTop .addressParking {
    margin-left: 20px;
    text-align: left;
    width: calc( 100% - 40px );
    font-size: 15pt;
    font-weight: 500;
}

.AccessListPage .List {
    margin-left: 5%;
    width: 90%;
}

.AccessListPage .viewParentTop .viewTop .changeAccessListView {
    width: calc( 100% - 40px );
    margin-left: 20px;
    margin-top: 10px;
}

.AccessListPage .viewParentTop .viewTop .changeAccessListView .TextFieldNumberAuto {
    display: inline-block;
    vertical-align: middle;
    width: 20%;
}

.AccessListPage .viewParentTop .viewTop .changeAccessListView .TextFieldNumberAuto .number_auto_input {
    height: 40px;
    border-radius: 10px 0 0 10px;
    box-shadow: none;
}

.AccessListPage .viewParentTop .viewTop .changeAccessListView .TextFieldCompany {
    display: inline-block;
    vertical-align: middle;
    width: 25%;
}

.AccessListPage .viewParentTop .viewTop .changeAccessListView .TextFieldCompany .company_input {
    height: 40px;
    border-radius: 0;
    box-shadow: none;
    border: none;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: rgb(213, 213, 213);
}

.AccessListPage .viewParentTop .viewTop .changeAccessListView .TextFieldDescription {
    display: inline-block;
    vertical-align: middle;
    width: 30%;
}

.AccessListPage .viewParentTop .viewTop .changeAccessListView .TextFieldDescription .description_input {
    height: 40px;
    border-radius: 0;
    box-shadow: none;
}

.AccessListPage .viewParentTop .viewTop .changeAccessListView .ButtonChangeView {
    display: inline-block;
    vertical-align: middle;
    width: 25%;
}

.AccessListPage .viewParentTop .viewTop .changeAccessListView .ButtonChangeView .ButtonChange {
    height: 40px;
    width: 100%;
    border: none;
    border-radius: 0 10px 10px 0;
    background-color: #FFCC00;
    color: black;
}

.AccessListPage .viewParentTop .viewTop .changeAccessListView .ButtonChangeView .ButtonChange:hover {
    box-shadow: 0px 0px 3px rgba(0 , 0, 5, 0.3);
}

@media screen and (max-width: 990px) {

    .AccessListPage .viewParentTop {
        width: 90%;
        margin-left: 5%;
        margin-top: 40px;
        margin-bottom: 30px;
        height: 200px;
    }

    .AccessListPage .List {
        margin-left: 5%;
        width: 90%;
    }

    .AccessListPage .viewParentTop .viewTop .changeAccessListView {
        width: 100%;
        margin-top: 10px;
        margin-left: 0px;
    }
    
    .AccessListPage .viewParentTop .viewTop .changeAccessListView .TextFieldNumberAuto {
        width: 100%;
    }
    
    .AccessListPage .viewParentTop .viewTop .changeAccessListView .TextFieldNumberAuto .number_auto_input {
        height: 40px;
        border-radius: 10px 10px 0 0;
        box-shadow: none;
    }
    
    .AccessListPage .viewParentTop .viewTop .changeAccessListView .TextFieldCompany {
        width: 100%;
    }
    
    .AccessListPage .viewParentTop .viewTop .changeAccessListView .TextFieldCompany .company_input {
        height: 40px;
        border-radius: 0;
        box-shadow: none;
        border-top: 0px solid;
        border-bottom: 0px solid;
        border-left: 1px solid;
        border-right: 1px solid;
        border-color: rgb(213, 213, 213);
    }
    
    .AccessListPage .viewParentTop .viewTop .changeAccessListView .TextFieldDescription {
        width: 100%;
    }
    
    .AccessListPage .viewParentTop .viewTop .changeAccessListView .TextFieldDescription .description_input {
        height: 40px;
        border-radius: 0;
        box-shadow: none;
    }
    
    .AccessListPage .viewParentTop .viewTop .changeAccessListView .ButtonChangeView {
        width: 100%;
    }

    .AccessListPage .viewParentTop .viewTop .changeAccessListView .ButtonChangeView .ButtonChange {
        height: 40px;
        width: 100%;
        border: none;
        border-radius: 0 0 10px 10px;
        background-color: #FFCC00;
        color: black;
    }
}

@media screen and (max-width: 400px) {

    .AccessListPage .viewParentTop {
        width: 95%;
        margin-left: 2.5%;
        margin-top: 40px;
        margin-bottom: 30px;
        height: 200px;
    }

    .AccessListPage .List input {
        margin-left: 2.5%;
        width: 95%;
    }

    .AccessListPage .viewParentTop .viewTop .addressParking {
        margin-left: 0px;
        text-align: center;
        width: 100%;
        font-size: 13pt;
        font-weight: 500;
    }

    .AccessListPage .viewParentTop .viewTop .changeAccessListView .TextFieldNumberAuto .number_auto_input {
        font-size: 11pt;
    }

    .AccessListPage .viewParentTop .viewTop .changeAccessListView .TextFieldCompany .company_input {
        font-size: 11pt;
    }

    .AccessListPage .viewParentTop .viewTop .changeAccessListView .TextFieldDescription .description_input {
        font-size: 11pt;
    }

    .AccessListPage .viewParentTop .viewTop .changeAccessListView .ButtonChangeView .ButtonChange {
        font-size: 11pt;
        color: black;
    }
}