.authModal .titleAuthModal {
    text-align: center;
    width: 100%;
}

.authButton {
    width: 100%;
}

@media screen and (min-width: 530px) and (max-width: 575px) {
    .authButton {
        margin-left: 10%;
    }
}

@media screen and (min-width: 510px) and (max-width: 530px) {
    .authButton {
        margin-left: 8%;
    }
}

@media screen and (min-width: 490px) and (max-width: 510px) {
    .authButton {
        margin-left: 6%;
    }
}

@media screen and (min-width: 450px) and (max-width: 490px) {
    .authButton {
        margin-left: 2%;
    }
}