.PassOrderPage .viewParentTop {
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;
    margin-bottom: 30px;
    height: 70px;
    display: block;
}

.PassOrderPage .viewParentTop .viewTop {
    display: inline-block;
    width: 100%;
}

.PassOrderPage .viewParentTop .viewTop .addressParking {
    margin-left: 0px;
    text-align: left;
    width: 100%;
    font-size: 15pt;
    font-weight: 500;
}

#ad {
    margin: 20px auto;
    font-size: 15pt;
    font-weight: 500;
    width: 90%;
    white-space: pre-line;
}

.PassOrderPage .List {
    margin-left: 5%;
    width: 90%;
}

.PassOrderPage .viewParentTop .viewTop .changeAccessListView {
    width: 100%;
    margin-top: 10px;
    border: 1px solid;
    border-color: rgb(192, 192, 192);
    border-radius: 10px;
}

.PassOrderPage .viewParentTop .viewTop .changeAccessListView .TextFieldNumberAuto {
    display: inline-block;
    width: 16%;
    border-right: 1px solid;
    border-color: rgb(192, 192, 192);
}

.PassOrderPage .viewParentTop .viewTop .changeAccessListView .TextFieldNumberAuto .number_auto_input {
    height: 40px;
    border-radius: 10px 0 0 10px;
    box-shadow: none;
    border: none;
    border-color: rgb(192, 192, 192);
}

.PassOrderPage .viewParentTop .viewTop .changeAccessListView .TextFieldCompany {
    display: inline-block;
    width: 17%;
    border-right: 1px solid;
    border-color: rgb(192, 192, 192);
}

.PassOrderPage .viewParentTop .viewTop .changeAccessListView .TextFieldCompany .company_input {
    height: 40px;
    box-shadow: none;
    border: none;
}

.PassOrderPage .viewParentTop .viewTop .changeAccessListView .TextFieldDateTimePicker {
    display: inline-block;
    vertical-align: middle;
    width: 17%;
}

.PassOrderPage .viewParentTop .viewTop .changeAccessListView .TextFieldDateTimePicker .TextFieldDateTimePickerInner {
    margin-left: 13px;
    margin-top: -3px;
    width: calc( 100% - 26px );
}

.PassOrderPage .viewParentTop .viewTop .changeAccessListView .TextFieldTerm {
    display: inline-block;
    width: 15%;
    background-color: white;
    border-left: 1px solid;
    border-right: 1px solid;
    border-color: rgb(192, 192, 192);
}


.PassOrderPage .viewParentTop .viewTop .changeAccessListView .TextFieldDescription {
    display: inline-block;
    width: 25%;
    border-right: 1px solid;
    border-color: rgb(192, 192, 192);
}

.PassOrderPage .viewParentTop .viewTop .changeAccessListView .TextFieldDescription .description_input {
    height: 40px;
    border-radius: 0;
    box-shadow: none;
    border: none
}

.PassOrderPage .viewParentTop .viewTop .changeAccessListView .ButtonChangeView {
    display: inline-block;
    width: 10%;
}

.PassOrderPage .viewParentTop .viewTop .changeAccessListView .ButtonChangeView .ButtonChange {
    height: 40px;
    width: 100%;
    border: none;
    border-radius: 0 10px 10px 0;
    background-color: #FFCC00;
    color: black;
}

.PassOrderPage .viewParentTop .viewTop .changeAccessListView .ButtonChangeView .ButtonChange:hover {
    box-shadow: 0px 0px 3px rgba(0 , 0, 5, 0.3);
}

@media screen and (max-width: 1150px) {

    .PassOrderPage .viewParentTop {
        width: 90%;
        margin-left: 5%;
        margin-top: 20px;
        margin-bottom: 30px;
        height: 260px;
        display: block;
    }

    .PassOrderPage .List {
        margin-left: 5%;
        width: 90%;
    }
    
    .PassOrderPage .viewParentTop .viewTop .changeAccessListView {
        width: 100%;
        margin-left: 0px;
        margin-top: 10px;
        border: 1px solid;
        border-color: rgb(192, 192, 192);
        border-radius: 10px;
        display: block;
    }
    
    .PassOrderPage .viewParentTop .viewTop .changeAccessListView .TextFieldNumberAuto {
        width: 100%;
        display: inline-block;
        border-right: 0px solid;
    }
    
    .PassOrderPage .viewParentTop .viewTop .changeAccessListView .TextFieldNumberAuto .number_auto_input {
        height: 40px;
        border-radius: 10px 10px 0 0;
        box-shadow: none;
        border: none;
        border-color: rgb(192, 192, 192);
    }
    
    .PassOrderPage .viewParentTop .viewTop .changeAccessListView .TextFieldCompany {
        display: inline-block;
        width: 100%;
        border-left: 0px;
        border-right: 0px;
        border-top: 1px solid;
        border-color: rgb(192, 192, 192);
    }
    
    .PassOrderPage .viewParentTop .viewTop .changeAccessListView .TextFieldCompany .company_input {
        height: 40px;
        border-radius: 0;
        box-shadow: none;
        border: none;
    }

    .PassOrderPage .viewParentTop .viewTop .changeAccessListView .TextFieldDateTimePicker {
        display: inline-block;
        width: 100%;
        height: 40px;
        border-top: 1px solid;
        border-color: rgb(192, 192, 192);
    }

    .PassOrderPage .viewParentTop .viewTop .changeAccessListView .TextFieldDateTimePicker .TextFieldDateTimePickerInner {
        margin-left: 13px;
        margin-top: 4px;
        width: calc( 100% - 26px );
    }
    
    .PassOrderPage .viewParentTop .viewTop .changeAccessListView .TextFieldTerm {
        width: 100%;
        background-color: white;
        border-left: 0px;
        border-right: 0px;
        border-top: 1px solid;
        border-bottom: 1px solid;
        border-color: rgb(192, 192, 192);
    }
    
    
    .PassOrderPage .viewParentTop .viewTop .changeAccessListView .TextFieldDescription {
        display: inline-block;
        width: 100%;
        border-right: 0px solid;
        border-top: 1px solid;
        border-color: rgb(192, 192, 192);
    }
    
    .PassOrderPage .viewParentTop .viewTop .changeAccessListView .TextFieldDescription .description_input {
        height: 40px;
        border-radius: 0;
        box-shadow: none;
        border: none
    }
    
    .PassOrderPage .viewParentTop .viewTop .changeAccessListView .ButtonChangeView {
        display: inline-block;
        width: 100%;
    }
    
    .PassOrderPage .viewParentTop .viewTop .changeAccessListView .ButtonChangeView .ButtonChange {
        height: 40px;
        width: 100%;
        border: none;
        border-radius: 0 0 10px 10px;
        background-color: #FFCC00;
        color: black;
    }
}

@media screen and (max-width: 400px) {

    .PassOrderPage .viewParentTop .viewTop .changeAccessListView .TextFieldNumberAuto .number_auto_input {
        font-size: 11pt;
    }

    .PassOrderPage .viewParentTop .viewTop .changeAccessListView .TextFieldCompany .company_input {
        font-size: 11pt;
    }

    .PassOrderPage .viewParentTop .viewTop .changeAccessListView .TextFieldDescription .description_input {
        font-size: 11pt;
    }

    .PassOrderPage .viewParentTop .viewTop .addressParking {
        text-align: center;
        font-size: 13pt;
        margin-left: 0px;
    }

    .ad {
        font-size: 13pt;
    }

    .PassOrderPage .viewParentTop {
        width: 95%;
        margin-left: 2.5%;
    }
    

    .PassOrderPage .List {
        margin-left: 2.5%;
        width: 95%;
    }
}