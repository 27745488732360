.buttonLogout {
    padding: 0;
    border: none;
    background-color: transparent;
    outline: none;
    width: 100%;
    height: 100%;
    text-align: center;
    color: black;
    font-weight: 500;
}